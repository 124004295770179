import { datadogRum } from "@datadog/browser-rum";

export const LEVEL = {
  ERROR: "error",
  WARNING: "warning",
};

/**
 * @description log custom errors in our error tracking system, by default level is "ERROR"
 *
 * @example
 * Here's a basic example:
 * ```
 * catch (error) {
 *  catchError(error, { reason: "Bid id is undefined" });
 * }
 * ```
 *
 * @example
 * Here's a warning example:
 * ```
 * catch (error) {
 *  catchError(error, { level: LEVEL.WARNING, reason: "Bid input is lower than reserve price" });
 * }
 * ```
 *
 */
export const catchError = datadogRum.addError;
